import React, { useEffect } from 'react'
import './ResponsiveFigure.scss'
import AOS from "aos";
import "aos/dist/aos.css";

const ResponsiveFigure = (props) => {
  const {mobile, desktop, caption} = props
  
  useEffect(() => {
    AOS.init({
      duration : 750,
      once: true,
    });
  }, []);

  return (
    <figure className="responsive-figure" data-aos="fade-up">
      <picture>
        <source srcSet={desktop.sourceUrl} media="(min-width: 768px)" />
        <img src={mobile.sourceUrl} alt={desktop.title} />
      </picture>
     {caption &&
        <figcaption
          className="text-mediumgray text-sm italic border-t border-mediumgray pt-4 mt-4"
          dangerouslySetInnerHTML={{__html: caption}}
        />
      }
    </figure>
  )
}

export default ResponsiveFigure
